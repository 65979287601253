<!-- 财务 -->
<template>
  <div class="box">
    <el-card>
      <!-- 搜索框 -->
      <el-row :gutter="12">
        <el-col :span="4">
          <el-input
            placeholder="请输入渠道名称"
            clearable
            v-model="name"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getChannelList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
    </el-card>
    <el-table :data="channelList" stripe style="width: 100%">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="name" label="渠道名称"> </el-table-column>
      <el-table-column prop="money" label="余额"> </el-table-column>
      <el-table-column prop="fund" label="扣款"> </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="goDetail(scope.row, 1)" type="text" size="small"
            >充值</el-button
          >
          <el-button @click="goDetail(scope.row, 0)" type="text" size="small"
            >扣款</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { qudaoList } from "@/network/channel.js";

export default {
  name: "report",
  data() {
    return {
      // 渠道列表
      channelList: [],
      name:''
    };
  },
  created() {
    let cooperType= window.sessionStorage.getItem('cooperType')
    let userId= window.sessionStorage.getItem('userId')
    if (cooperType&&cooperType==='6'&&userId){
      this.$router.push({
        path: "financeDetail",
        query: {
          id: userId,
          name:window.sessionStorage.getItem('userName')||'',
          type: 2
        },
      });
    }
    this.getChannelList();
  },
  methods: {
    // 去详情
    goDetail(row, type) {
      console.log("row: ", row, type);
      this.$router.push({
        path: "financeDetail",
        query: {
          id: row.ID,
          name:row.name,
          type: type
        },
      });
    },
    // 获取渠道
    async getChannelList() {
      this.channelList = [];
      let res = await qudaoList({name:this.name});
      console.log("res: ", res);
      if (res.data && res.data.length > 0) {
        this.channelList = res.data;
      }
    },
  },
};
</script>

<style scoped>
</style>
